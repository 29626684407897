import React from 'react'

const Logo = () => {
  return (
    <svg version="1.1" id="Capa_1"  x="0px" y="0px" viewBox="0 0 600 200" enable-background="new 0 0 600 200" >
      <path id="_x34_S" fill="#DC1943" d="M44.74,31.13h35.38l-15.5,86.52c-10.04,0-30-6.42-29.53-32.12L44.74,31.13z M97.38,31.13h34.8
      l-24.85,137.74h-34.8L97.38,31.13z M142.99,65.85h111.99l6.14-34.73h-78.07C172.43,31.52,149.54,39,142.99,65.85z M140.07,82.93
      h79.24c9.94,0.19,29.18,7.35,26.61,34.43h-78.66C156.83,117.07,136.79,109.78,140.07,82.93z M131,134.44h111.7
      c-1.66,10.22-11.52,31.43-37.72,34.43h-80.12L131,134.44z" />
      <g id="ADMIN">
        <path fill="#DC1943" d="M323.66,99.66l9.72-20.21h13.24l0.26,1.2c0.17,1.32,1.12,8.74,2.84,22.27c1.72,13.53,2.58,20.47,2.58,20.81
        c0,0.17-0.03,0.3-0.09,0.39c-0.06,0.09-0.29,0.17-0.69,0.26c-0.4,0.09-0.93,0.13-1.59,0.13c-0.66,0-1.68,0-3.05,0
        c-3.67,0-5.5-0.06-5.5-0.17c-0.12-1.2-0.26-2.52-0.43-3.96l-0.43-3.96h-13.93c-0.75,1.78-1.92,4.39-3.53,7.83l-5.59,0.17
        l-5.68,0.09l1.12-2.32C314.68,118.4,318.27,110.89,323.66,99.66z M339.39,106.02l-1.12-16.34c-1.09,2.47-2.29,5.22-3.61,8.26
        l-3.61,8.34l4.13,0.09c0.4,0,0.86,0.01,1.38,0.04c0.52,0.03,0.92,0.04,1.2,0.04c0.29,0,0.57-0.01,0.86-0.04
        c0.29-0.03,0.49-0.07,0.6-0.13C339.34,106.22,339.39,106.14,339.39,106.02z" />
        <path fill="#DC1943" d="M369.32,79.45l0.17-0.86l11.7,0.17c5.45,0.06,8.86,0.13,10.23,0.22s2.58,0.33,3.61,0.73
        c2.75,1.15,4.64,2.87,5.68,5.16c0.92,1.89,1.38,5.02,1.38,9.37c0,1.26-0.03,2.24-0.09,2.92c-0.29,6.14-1.49,11.45-3.61,15.95
        c-2.12,4.5-4.87,7.61-8.26,9.33c-1.78,0.92-3.7,1.49-5.76,1.72c-2.06,0.23-6.05,0.34-11.95,0.34c-2.64,0-4.63,0-5.98,0
        c-1.35,0-2.41-0.03-3.18-0.09c-0.77-0.06-1.25-0.13-1.42-0.21s-0.26-0.21-0.26-0.39c0-0.29,1.23-7.5,3.7-21.63
        C367.75,88.06,369.09,80.48,369.32,79.45z M390.91,94.93c0-2.69-0.46-4.33-1.38-4.9c-0.92-0.57-2.98-0.86-6.19-0.86l-4.13,0.09
        l-0.34,1.55c-0.17,1.09-0.45,2.68-0.82,4.77c-0.37,2.09-0.73,4.08-1.08,5.98l-2.06,12.21l3.53,0.09h1.72c1.72,0,2.75-0.03,3.1-0.09
        c1.78-0.29,3.24-1.58,4.39-3.87c0.8-1.66,1.55-4.03,2.24-7.1C390.56,99.73,390.91,97.11,390.91,94.93z" />
        <path fill="#DC1943" d="M424.1,79.96l0.34-1.2h11.27l0.17,1.2c0.06,0.17,0.69,4.85,1.89,14.02c1.2,9.17,1.86,13.93,1.98,14.28
        c0,0.12,0.06,0.17,0.17,0.17c0.4,0,2.75-4.79,7.05-14.36l6.88-15.14l5.68-0.17l5.68-0.09l-0.26,3.35
        c-0.92,24.02-1.43,36.58-1.55,37.67l-0.17,4.3h-11.78l0.26-2.92c0.34-5.04,0.52-9.98,0.52-14.79c0-2.41-0.09-3.61-0.26-3.61
        c-0.06,0-1.55,3.64-4.47,10.92l-4.13,10.23c-1.26,0-3.14,0.03-5.63,0.09c-2.49,0.06-4.37,0.09-5.63,0.09l-0.26-1.03
        c-0.06-0.29-0.25-2.21-0.56-5.76c-0.32-3.55-0.62-7.02-0.9-10.41l-0.52-4.99c-0.06,0-0.55,1.66-1.46,4.99
        c-0.92,3.33-1.85,6.68-2.79,10.06c-0.95,3.38-1.42,5.1-1.42,5.16l-0.52,1.98h-6.11c-3.96-0.06-5.91-0.23-5.85-0.52
        C412.24,121.47,416.36,106.97,424.1,79.96z M452.4,101.38c0,0.17,0.03,0.26,0.09,0.26l0.17-0.26c0.06-0.11,0.06-0.2,0-0.26
        C452.48,101,452.4,101.09,452.4,101.38z" />
        <path fill="#DC1943" d="M481.89,78.93c0-0.17,2.01-0.26,6.02-0.26c2.87,0,4.59,0.03,5.16,0.09c0.57,0.06,0.86,0.23,0.86,0.52
        c0,0.46-1.15,7.94-3.44,22.45l-3.53,21.84c-1.38,0.06-3.43,0.09-6.15,0.09c-2.72,0-4.74,0.03-6.06,0.09l0.26-0.86
        c0.11-0.52,1.29-7.91,3.53-22.19C480.78,86.41,481.89,79.16,481.89,78.93z" />
        <path fill="#DC1943" d="M510.62,79.19c0.17-0.29,1.29-0.43,3.35-0.43h1.63l4.73,0.17l5.25,11.09c0.69,1.49,1.53,3.33,2.54,5.5
        c1,2.18,1.75,3.8,2.24,4.86c0.49,1.06,0.79,1.68,0.9,1.85c0.11,0.17,0.2,0.26,0.26,0.26c0.29,0,0.97-3.53,2.06-10.58
        c0.52-3.61,1.17-7.74,1.98-12.38c0.11-0.34,0.34-0.56,0.69-0.64c0.34-0.09,1.35-0.13,3.01-0.13h2.49c4.01,0,6.02,0.03,6.02,0.09
        c0,0.17-1.13,7.43-3.4,21.76c-2.27,14.33-3.46,21.79-3.57,22.36l-0.26,1.03h-9.63l-5.59-11.95c-3.5-7.51-5.45-11.27-5.85-11.27
        l-0.09,0.09c-0.12,0.29-0.76,4.06-1.93,11.31c-1.18,7.25-1.76,11.08-1.76,11.48c0,0.23-2.01,0.34-6.02,0.34
        c-4.01,0-6.02-0.17-6.02-0.52c0-0.29,1.13-7.67,3.4-22.15C509.31,86.86,510.5,79.48,510.62,79.19z" />
      </g>
    </svg>
  )
}
export default Logo;