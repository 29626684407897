import React from 'react'
import './App.css';
import { ThemeProvider } from '@mui/system';
import theme from './theme';
import Routing from './routes/routes';
import { AuthProvider } from './contexts/authContext';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Routing />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
