import React from "react";
import { List } from "@mui/material";
import paths from "../../utils/AllRoutesPages";

import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import SportsTennisRoundedIcon from '@mui/icons-material/SportsTennisRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import NewspaperRoundedIcon from '@mui/icons-material/NewspaperRounded';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import EListAltIcon from '@mui/icons-material/ListAlt';

import AppMenuItem from './AppMenuItem.tsx';

const appMenuItems = [
    {
      name: 'Reservas',
      link: paths.BOOKING_LIST,
      Icon: TodayRoundedIcon,
    },
    {
      name: 'Usuarios',
      Icon: PeopleAltRoundedIcon,
      items: [
        {
            name: 'Usuarios App',
            link: paths.USER_APP_LIST,
            Icon: PeopleAltRoundedIcon,
        },
        {
            name: 'Usuarios Admin',
            link: paths.USER_ADMIN_LIST,
            Icon: PeopleAltRoundedIcon,
        },
      ],
    },
    {
      name: 'Pistas',
      link: paths.COURTS_LIST,
      Icon: SportsTennisRoundedIcon,
    },
    {
      name: 'Horarios',
      Icon: CalendarMonthIcon,
      items: [
        {
          name: 'Tipos de horas',
          link: paths.HOUR_TYPE_LIST,
          Icon: MoreTimeIcon,
        },
        {
          name: 'Plantillas',
          link: paths.TEMPLATES_LIST,
          Icon: EListAltIcon,
        },
        {
          name: 'Calendario de plantillas',
          link: paths.CALENDAR_TEMPLATE,
          Icon: CalendarMonthIcon,
        },
      ],
    },
    {
      name: 'Noticias',
      link: paths.NEWS_LIST,
      Icon: NewspaperRoundedIcon,
    },
    
  ]

  const AppMenu: React.FC = () => {

    return (
      <List component="nav" disablePadding>
        {appMenuItems.map((item, index) => (
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    )
  }

  export default AppMenu