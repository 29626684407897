const paths = {
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    ERROR: '/error',
    BOOKING_LIST: '/admin/bookings',

    USER_APP_LIST: '/admin/usersapps',

    USER_ADMIN_LIST: '/admin/usersadmin',
    USER_ADMIN_CREATE: '/admin/usersadmin/create',
    USER_ADMIN_EDIT: '/admin/usersadmin/edit/:id',

    COURTS_LIST: '/admin/courts',
    COURTS_CREATE: '/admin/courts/create',
    COURTS_EDIT: '/admin/courts/edit/:id',

    NEWS_LIST: '/admin/news',
    NEWS_CREATE: '/admin/news/create',
    NEWS_EDIT: '/admin/news/edit/:id',

    CALENDAR_TEMPLATE: '/admin/calendarTemplate',

    BLOG: '/blog',
    POST_VIEW: '/:url',

    HOUR_TYPE_LIST: '/admin/hours',

    TEMPLATES_LIST: '/admin/templates',
    TEMPLATE_CREATE: '/admin/templates/create',
    TEMPLATE_EDIT: '/admin/templates/edit/:id'

};

export default paths;