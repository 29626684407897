import React, { Suspense } from "react";
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';
import ResponsiveDrawer from "../components/layout/Layout";
import Loading from "../components/MuiLoading/Loading";
import NotFound from "../components/notFound/NotFound";
import paths from "../utils/AllRoutesPages";
import ProtectecRoutes from "./ProtectecRoutes";

const UserAdminCreateAndEdit = React.lazy(() => import('../pages/usersAdmin/UserAdminCreateAndEdit'))
const UserAdminList = React.lazy(() => import('../pages/usersAdmin/UserAdminList'))
const HourTypeList = React.lazy(() => import('../pages/HourType/HourTypeList'))
const TemplateCreateAndEdit = React.lazy(() => import('../pages/templates/TemplateCreateAndEdit'))
const TemplatesList = React.lazy(() => import('../pages/templates/TemplatesList'))
const CalendarTemplate = React.lazy(() => import('../pages/calendarTemplate/CalendarTemplate'));
const BookingList = React.lazy(() => import('../pages/bookings/BookingList'));
const UsersAppList = React.lazy(() => import('../pages/usersApp/UserAppList'));
const CourtsList = React.lazy(() => import('../pages/courts/CourtsList'));
const CourtsCreateAnEdit = React.lazy(() => import('../pages/courts/CourtsCreateAndEdit'));
const NewsList = React.lazy(() => import('../pages/news/NewsList'));
const NewsCreateAnEdit = React.lazy(() => import('../pages/news/NewsCreateAndEdit'));
const Blog = React.lazy(() => import('../pages/blog/pages/Blog'))
const SignIn = React.lazy(() => import('../pages/singIn/SingIn'));
const PostView = React.lazy(() => import('../pages/blog/pages/PostView'))


const Routing = () => {

    return (
        <Suspense fallback={<Loading />}>
            <Router>
                <Routes>
                    <Route path={paths.SIGN_IN} element={<SignIn />} />

                    <Route element={<ProtectecRoutes />}>
                        {/* TODO Ruta que por default nos lleva a / deberia llevarnos a futuro a un Home o Dashboard */}
                        <Route path='/admin' element={<ResponsiveDrawer />}>
                            <Route path={paths.BOOKING_LIST}        element={<BookingList />} />
                            <Route path={paths.USER_APP_LIST}       element={<UsersAppList />} />
                            <Route path={paths.USER_ADMIN_LIST}     element={<UserAdminList />} />
                            <Route path={paths.USER_ADMIN_CREATE}   element={<UserAdminCreateAndEdit />} />
                            <Route path={paths.USER_ADMIN_EDIT}     element={<UserAdminCreateAndEdit />} />
                            <Route path={paths.COURTS_LIST}         element={<CourtsList />} />
                            <Route path={paths.COURTS_CREATE}       element={<CourtsCreateAnEdit />} />
                            <Route path={paths.COURTS_EDIT}         element={<CourtsCreateAnEdit />} />
                            <Route path={paths.NEWS_LIST}           element={<NewsList />} />
                            <Route path={paths.NEWS_CREATE}         element={<NewsCreateAnEdit />} />
                            <Route path={paths.NEWS_EDIT}           element={<NewsCreateAnEdit />} />
                            <Route path={paths.HOUR_TYPE_LIST}      element={<HourTypeList />} />
                            <Route path={paths.CALENDAR_TEMPLATE}   element={<CalendarTemplate />} />
                            <Route path={paths.TEMPLATES_LIST}      element={<TemplatesList />} />
                            <Route path={paths.TEMPLATE_CREATE}     element={<TemplateCreateAndEdit />} />
                            <Route path={paths.TEMPLATE_EDIT}       element={<TemplateCreateAndEdit />} />
                        </Route>
                    </Route>

                    <Route path={'/'} element={<Blog />} />
                    
                    <Route path={paths.POST_VIEW} element={<PostView />} />
                    <Route path='*' element={<NotFound />} />
                    <Route path='404' element={<NotFound />} />
                </Routes>
            </Router>
        </Suspense >
    );
}

export default Routing;