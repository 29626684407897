import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';

const theme = createTheme({
	shape: {
		borderRadius: 6,
	},
	palette: {
		primary: {
			main: '#DC113E',
		},
		error: {
			main: '#DB2020',
		},
		success: {
			main: '#2BC54D',
		},
		warning: {
			main: '#ED6C02',
		},
	},
	esES,
});

export default theme;
