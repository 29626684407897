import * as React from 'react';
import PropTypes from 'prop-types';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, Toolbar, Typography, Tooltip} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

import AppMenu from '../appMenu/AppMenu.tsx';
import Logo from './Logo';

import { useAuth } from '../../contexts/authContext';
import paths from '../../utils/AllRoutesPages';

import config from '../../config/config'
import PageTitle from '../PageTitle/PageTitle';
import theme from '../../theme';
import { ThemeProvider } from '@mui/material/styles';

const drawerWidth = 240;

function ResponsiveDrawer(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const { isAuthenticated, logout, currentUser } = useAuth();
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // const handleMenu = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const handleCloseSesion = () => {
        logout()
        navigate(paths.SIGN_IN)
    }

    // Aca pondemos los paths y el nombre del modulo para que se cambie en el appbar cuando navegamos.
    const titles = React.useMemo(() => {
        const pathsPage = {
            "/": "",
            "/admin/bookings": "Reservas",
            "/admin/users": "Usuarios",
            "/admin/courts": "Pistas",
            "/admin/courts/create": "Nueva Pista",
            "/admin/courts/edit/:id": "Editar Pista",
            "/admin/calendarTemplate": "Calendario de Plantillas",
            "/admin/hours": "Tipo de Horas",
            "/admin/templates": "Plantillas",
            "/admin/templates/create": "Nueva plantillas",
            "/admin/usersadmin":"Usuarios Admin",
            "/admin/usersadmin/create":"Nuevo Usuario",
            "/admin/usersapps":"Usuarios App",
        };

        return pathsPage
    }, [])

    // Inside Toolbar component
    const location = useLocation();
    const [title, setTitle] = React.useState(titles["/"]);

    React.useEffect(() => {
        setTitle(titles[location.pathname]);
    }, [location.pathname, titles]);


    const drawer = (
        <div>
            <Toolbar>
                <Logo />
            </Toolbar>
            <Divider />
            <AppMenu />
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <PageTitle title={title + config.TITLE_SEPARATION + config.ADMIN_TITLE_NAME}/>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                    }}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {title}
                        </Typography>
                        {isAuthenticated && (
                            <div>
                                <Tooltip title='Cerrar sessión'>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleCloseSesion}
                                        color="inherit"
                                    >
                                        <LogoutIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={currentUser && currentUser.email}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        color="inherit"
                                    >
                                        <AccountCircleIcon />
                                    </IconButton>
                                </Tooltip>

                                {/* <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose}>Perfil</MenuItem>
                                    <MenuItem onClick={handleCloseSesion}>cerrar sesion</MenuItem>
                                </Menu> */}
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Outlet />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
