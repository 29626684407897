// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBdvux8bUfl1-3wa0TLBz9YW_zo4p15PSM",
  authDomain: "sport-ejea.firebaseapp.com",
  projectId: "sport-ejea",
  storageBucket: "sport-ejea.appspot.com",
  messagingSenderId: "746701100228",
  appId: "1:746701100228:web:963ac82d1030fb38ace591"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);